import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Section from '~components/Section'
import { Link, useStaticQuery, graphql } from "gatsby"
import Button from '~components/blocks/Button'
import NewsletterSignupForm from '~components/blocks/NewsletterSignupForm'
import { Uarr } from '../../Svg';
import resolveLink from '~utils/resolveLink'
import useSiteSettings from '~utils/useSiteSettings'
import { paramCase } from "change-case"

const Footer = ({ className, links, socialLinks, copyright }) => {

  const { footerLinks, abn, facebookUrl, linkedInUrl, instagramUrl, pinterestUrl, showrooms, footerContactSuccessMessage } = useSiteSettings()

  const {mobile} = breakpoints

  const footerUlCss = `
    list-style-type: none;
    ${mobile}{
      margin-bottom: 22px;
    }
  `
  const footerLiCss = css`
    margin-top: 0px;
    margin-bottom: 10px;
    ${mobile}{
      margin-bottom: 5px;
    }
  `

  return (
    <Section
      className={className}
      css={css`
      > div {
        grid-template-rows: minmax(335px, max-content) max-content;
        ${mobile}{
          grid-template-rows: auto;
        }
      }
      `}
    >
      <ul
        css={css`
          ${footerUlCss}
          grid-row: 1;
          grid-column: 1 / 3;
          ${mobile}{
            grid-column: 1 / 6;
          }
      `}>
        {footerLinks?.map(link => (
            <li
              key={link?._key}
              className="h6"
              css={footerLiCss}
            >
                <Link to={resolveLink(link?.to?._type, link?.to?.slug?.current)}>
                    {link?.linkText}
                </Link>
            </li>
        ))}
      </ul>
      <ul
        css={css`
          ${footerUlCss}
          grid-row: 1;
          grid-column: 3 / 5;
          ${mobile}{
            grid-column: 7 / 13;
          }
      `}>
        {facebookUrl && 
        <li
          className="h6"
          css={footerLiCss}
        >
            <Button to={facebookUrl} external noUnderline>
                Facebook
            </Button>
        </li>
        }
        {instagramUrl &&
          <li
            className="h6"
            css={footerLiCss}
          >
            <Button to={instagramUrl} external noUnderline>
              Instagram
            </Button>
          </li>
        }
        {pinterestUrl &&
          <li
            className="h6"
            css={footerLiCss}
          >
            <Button to={pinterestUrl} external noUnderline>
              Pinterest
            </Button>
          </li>
        }
        {linkedInUrl &&
          <li
            className="h6"
            css={footerLiCss}
          >
            <Button to={linkedInUrl} external noUnderline>
              LinkedIn
            </Button>
          </li>
        }
      </ul>
      <div
        css={css`
          grid-row: 1;
          grid-column: 5 / 9;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 20px;
          ${mobile}{
            grid-row: 2;
            grid-column: 1 / 13;
            grid-column-gap: 10px;
            margin-bottom: 40px;
          }
      `}>
        {showrooms?.map(showroom => (
          <div
            key={showroom?._key}
            css={css`
              grid-column: span 1;
          `}>
              <h6 css={css`margin-bottom: 12px;`}><Link to={`/showrooms#${paramCase(showroom?.title)}`}>{showroom?.title}</Link></h6>
              <a href={showroom?.mapUrl} target="blank">
                <div className="h6">{showroom?.address}</div>
                <div className="h6">{showroom?.city} {showroom?.state} {showroom?.postCode}</div>
                <div className="h6">{showroom?.country}</div>
                <div className="h6">{showroom?.phoneNumber}</div>
              </a>
          </div>
        ))}
      </div>
      <div css={css`
        grid-row: 1;
        grid-column: 10 / 12;
        ${mobile}{
          grid-row: 4;
          grid-column: 1 / 11;
        }
      `}>
        <NewsletterSignupForm heading={'subscribe'} successMessage={footerContactSuccessMessage}/>
      </div>
      <div css={css`
        grid-row: 2;
        grid-column: 1 / 7;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 20px;
        ${mobile}{
          grid-row: 3;
          grid-column: 1 / 13;
          grid-column-gap: 10px;
          margin-bottom: 80px;
          grid-template-columns: 1fr 1fr;
          grid-row-gap: 16px;
        }
      `}>
        <h6>© {new Date().getFullYear()} Misura</h6>
        <h6>ABN {abn}</h6>
        <h6>Design &amp; Build by <a href="https://afom.com.au/" target="_blank">AFOM</a></h6>
      </div>
      <div css={css`
        grid-row: 2;
        grid-column: 7 / 9;
        ${mobile}{
          grid-row: 5;
          grid-column: 1 / 4;
          margin-top: 50px;
        }
      `}>
        <Button
          noUnderline
          onClick={() => window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })}>
          <Uarr css={css`
            width: 8px;
            height: 8px;
            margin-right: 6px;
          `}/>
          back to top
        </Button>
      </div>
    </Section>
  )
}

Footer.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    name:PropTypes.string,
    link: PropTypes.string,
    key: PropTypes.string,
  })),
  socialLinks: PropTypes.arrayOf(PropTypes.shape({
    name:PropTypes.string,
    link: PropTypes.string,
    key: PropTypes.string,
  })),
  showrooms: PropTypes.array,
}

Footer.defaultProps = {
  links: [
    {name:'About us',link:"/about-us", key:"1"},
    {name:'Our Showrooms',link:"/our-showrooms", key:"2"},
    {name:'Our Brands',link:"/our-brands", key:"3"},
    {name:'Contact',link:"/contact", key:"4"},
    {name:'trade',link:"/trade", key:"5"},
    {name:'Terms & conditions',link:"/terms-and-conditions", key:"6"},
    {name:'Guides',link:"/guides", key:"7"},
    {name:'Login',link:"/account", key:"8"},
  ],
  socialLinks: [
      {name:'Facebook',link:"/facebook", key:"facebook"},
      {name:'Instagram',link:"/instagram", key:"instagram"},
      {name:'Pinterest',link:"/pinterest", key:"pinterest"},
  ],
  showrooms: [
      { name:"Sydney showroom",
        addressLine1:"840 Bourke Street,",
        addressLine2:"Waterloo NSW 2017",
        phoneNumber: "+61 2 8399 5630",
        key:"1"
      },
      { name:"Melbourne showroom",
        addressLine1:"610 Church Street,",
        addressLine2:"Cremorne VIC 3121" ,
        phoneNumber:"+61 3 9429 1221",
        key:"2"
      },
  ],
}

export default Footer
