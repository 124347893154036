/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"

import { SiteStore } from "~context/siteContext"

export const wrapRootElement = ({ element }) => (
  <SiteStore>{element}</SiteStore>
)

// Currently this means that the back button doesn't preserve the scroll position, which could become problematic when going back.
// There must be a way to honour the previous scroll position when the back button is pressed
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll

// const transitionDelay = 550
// //
// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   prevRouterProps,
//   getSavedScrollPosition,
// }) => {
//
//   // console.log(window.locations[2] === location.href)
//   if (location.action === 'PUSH') {
//     window.setTimeout(() => {
//       window.scrollTo(0, 0)
//       console.log('toTop')
//     }, transitionDelay)
//   } else {
//     const savedPosition = getSavedScrollPosition(location)
//     window.setTimeout(
//       () => {
//         window.scrollTo(...(savedPosition || [0, 0]))
//         console.log(savedPosition)
//       }, transitionDelay)
//   }
//   return false
// }


// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   console.log(location)
//   if (location.action === "PUSH") {
//     window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
//   } else {
//     const savedPosition = getSavedScrollPosition(location)
//     // console.log(savedPosition)
//     window.setTimeout(
//       () => window.scrollTo(...(savedPosition || [0, 0])),
//       transitionDelay
//     )
//   }
//   return false
// }

// export const shouldUpdateScroll = (props) => {
//   window.setTimeout(() => {
//     window.scrollTo(0, 0)
//     console.log('toTop')
//   }, transitionDelay)
//   console.log(props)
//   return false
// }

// This is to stop scrolling on route change when filtering updates the url, will need to expand on it for the broader page transitioning

// export const shouldUpdateScroll = ({ routerProps }) => {
//   if(routerProps.location.state){
//     const { disableScrollUpdate } = routerProps.location.state
//     return !disableScrollUpdate
//   }
// }

const transitionDelay = 640
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.history.scrollRestoration = "manual"
  if(location.state?.disableScrollUpdate || location.search.includes('?brand=')){
    return false
  }
  const currentPosition = getSavedScrollPosition(location)
  window.setTimeout(() => {
    window.scrollTo(...currentPosition)
  }, transitionDelay )
  return false
}

// export const onInitialClientRender = () => {
//   window.history.scrollRestoration = "manual";
// }
