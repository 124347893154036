import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Figure from "~components/modules/Figure";
import Section from '~components/Section'

const ImageModule = ({ image, className, aspectRatio, caption }) => {

  const {mobile} = breakpoints
  let portrait = aspectRatio <= 1

  return (
    <Section  
      className={className} 
      css={css`
    `}>
				<div css={css`
            grid-column: ${portrait ? `5/9` : `3/11`};
            ${mobile} {
              grid-column: span 12;
            }
          `}>
					<Figure 
						css={css`
							max-width: ${portrait ? `900px` : `1200px`};
							margin: 0 auto;
						`}
						image={image} 
						caption={caption} 
						aspectRatio={aspectRatio}
					/>
				</div>
    </Section>
  )
}

ImageModule.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  caption: PropTypes.array,
  aspectRatio: PropTypes.number,
}

ImageModule.defaultProps = {
  caption: [
    
  ],
  aspectRatio: 1.78,
}


export default ImageModule

