import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { breakpoints } from '~styles/global'
import { SearchIcon } from '~components/Svg'
import { useSiteState } from '~context/siteContext'

const SearchInput = ({ className, initialValue, onChange, onSubmit, focusOnOpen }) => {
  const inputRef = useRef()
  const [value, setValue] = useState()
  const { tablet } = breakpoints
  const [siteState, setSiteState] = useSiteState()

  useLayoutEffect(() => {
    if(initialValue){
      inputRef.current.value = initialValue
      setValue(initialValue)
    }
    // auto focus on load
    inputRef.current.focus()
  }, [initialValue])
  
  useEffect(() => {
    if(focusOnOpen && siteState.searchOpen){
      inputRef.current.focus()
    }
  }, [siteState.searchOpen])

  const handleChange = val => {
    onChange(val)
    setValue(val)
  }

  const handleSubmit = e => {
    e.preventDefault()
    if(onSubmit){
      onSubmit(e)
    }
  }

  return (
    <form
      className={className}
      onSubmit={e => handleSubmit(e)}
      css={css`
        position: relative;
        input{
          font-size: 15px;
          line-height: 19px;
          letter-spacing: 0.01em;
          font-weight: 400;
          padding-right: 30px;
          &:focus{
            padding-right: 30px;
          }
          ${tablet} {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0em;
          }
        }
        button{
          width: 20px;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          ${tablet}{
            width: 15px;
          }
        }
      `}
    >
        <input type="text" onChange={e => handleChange(e.target.value)} ref={inputRef} />
        <button role="submit">
          <SearchIcon />
        </button>
    </form>
  )
}

SearchInput.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
}

SearchInput.defaultProps = {
  // initialValue: 'value',
  onChange: e => console.log(e),
  onClick: e => console.log(e),
}

export default SearchInput
