import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Image from '~components/Image'
import Button from '~components/blocks/Button'
import { Link } from 'gatsby';

const MenuCard = ({ className, image, cta, shortDesc, link, type, brandLink }) => {

  const {mobile} = breakpoints
  const [hovered, setHovered] = useState(true)

  return (
    <div
      className={className}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    > 
      <div css={css`
        margin-bottom: 38px;
        overflow: hidden;
        ${mobile}{
          margin-bottom: 28px;
        }
      `}>
        <div css={css`
          transform: ${hovered ? `scale(1.03)` : `scale(1)`};
          transition: 0.3s ease-in-out transform;
          ${mobile}{
            transform: none;
          }
        `}>
          <Link to={link}>
            <Image
              asset={image}
              aspectRatio={1.53}
            />
          </Link>
        </div>
      </div>
      <p css={css`
        padding-bottom: 32px;
        ${mobile}{
          font-size: 12px;
          line-height: 15.5px;
          padding-bottom: 36px;
        }
      `}>
        {shortDesc}
      </p>
      {brandLink && 
        <Button 
          to={brandLink}
          css={css`
            margin-bottom: 24px;
            ${mobile}{
              margin-bottom: 22px;
            }
          `}
        >
          More about the brand
        </Button>
      }
      <Button to={link}>{type === `brand` ? `View Products` : `View Range`}</Button>
    </div>
  )
}

MenuCard.propTypes = {
  image: PropTypes.object, 
  link: PropTypes.string, 
  title: PropTypes.string, 
  shortDesc: PropTypes.string,
}

MenuCard.defaultProps = {
}

export default MenuCard
