import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import { Darr } from "~components/Svg"

const Download = ({ className, name, file, inline }) => {
  const { mobile } = breakpoints

  const underLineCss = `
    > span {
      position: relative;
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--black);
        transition: transform 0.2s;
        position: absolute;
        bottom: 0px;
        display: block;
        transform: translateY(1px);
      }
    }
    &:hover{
      > span{
        &:after{
          transform: translateY(4px);
        }
      }
    }
  `

  return (
  <a
    className={`h6 ` + className}
    href={file ? file?.asset?.url + '?dl=' + file?.asset?.originalFilename: null}
    css={css`
      display: ${inline ? 'inline-grid' : 'grid'};
      align-items: center;
      grid-template-columns: max-content max-content;
      margin: 0 ${inline ? '3.8em' : '0'} 1em 0;
      
      ${underLineCss}
  `}>
    <span>
      {name}
    </span>
    <Darr css={css`
      width: 10px;
      height: 10px;
      margin-left: 14px;
      ${mobile}{
        margin-left: 4px;
      }
    `}/>
  </a>
  )
}

Download.propTypes = {
  name: PropTypes.string,
  file: PropTypes.object,
  inline: PropTypes.bool,
}

Download.defaultProps = {
  name: 'File Name',
}

export default Download
