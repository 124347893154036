import React, { useCallback } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import { PlayButton } from '../../Svg';
import Player from "@vimeo/player"
import RichText from '~components/RichText'
import Image from '~components/Image'

const VideoModule = ({ video, caption, placeholder, className }) => {

  const { mobile } = breakpoints

  let vimeo
  let youtube

  if (video?.includes(`vimeo`)){
    vimeo = true
  } else if (video?.includes(`youtube`) && video?.includes(`?v=`)) {
    youtube = true
    video = video.split(`?v=`)[1]
    video = `https://www.youtube.com/embed/${video}`
  } else if (video?.includes(`youtube`)) {
    youtube = true
    video = video.split(`embed/`)[1]
    video = `https://www.youtube.com/embed/${video}`
  } 

  // youtube play function 
  const [play, setPlay] = React.useState(false);

  const url = play ? `${video}?autoplay=1` : video

  // Vimeo variables
  let player

  const videoContainer = useCallback(node => {
    let options = {
      id: video,
      loop: true,
      byline: false,
      title: false,
      portrait: false,
    }
    if(node) player = new Player(node, options)
  }, [])

  const playVideo = () => {
    setPlay(true)
    player?.play()
  }

  return (
    <div 
      className={className}       
      css={css`
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 20px;
        ${mobile}{
          grid-column-gap: 10px;
        }
    `}>
      <div css={css`
        grid-column: 3/11;
        ${mobile} {
          grid-column: span 12;
        }
      `}>
        <div css={css`
          position: relative;
          margin-bottom: 20px;
        `}>
					{/* Hidden based on request */}
          {/* <div css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            pointer-events: ${ play ? `none` : `auto` };
            opacity: ${ play ? `0` : `1` };
            transition: 0.5s opacity;
            display: grid;
            align-content: center;
            justify-content: center;
          `}>
            <button onClick={playVideo}>
              <PlayButton css={css`
                width: 40px; 
                height: 40px;
                position: relative;
                z-index: 2;
              `}/>
            </button>
            <div css={css`
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              height: 100%;
            `}>
              <Image asset={placeholder} aspectRatio={1.7778}/>
            </div>
          </div> */}
          <div css={css`
            position: relative; 
            padding-bottom: 56.25%;
            height: 0; 
            overflow: hidden; 
            max-width: 100%;
          `}>
            {youtube && 
              <iframe 
                src={url} 
                frameBorder='0' 
                allowFullScreen
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                css={css`
                  position: absolute; 
                  top: 0; 
                  left: 0; 
                  width: 100%; 
                  height: 100%; 
              `}>
            </iframe>}
            {vimeo && 
              <div ref={videoContainer} css={css`
                grid-column: span 4;
                position: relative; 
                padding-bottom: 56.25%; 
                height: 0; 
                overflow: hidden; 
                max-width: 100%;
                iframe{
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                }
              `}/>
            }
          </div>
        </div>
        <div className="h6 caption">
          <RichText content={caption}/>
        </div>
      </div>
    </div>
  )
}

VideoModule.propTypes = {
  className: PropTypes.string,
  video: PropTypes.string,
  caption: PropTypes.array,
}

VideoModule.defaultProps = {
  
}

export default VideoModule
