import React from 'react'
import { css } from "@emotion/react"
import { breakpoints } from '~styles/global'

const Section = ({ children, className, id }) => {

  const { mobile } = breakpoints

  return (
    <div className={className} id={id}>
      <div css={css`
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 20px;
        padding: 0 40px;
        ${mobile}{
          grid-column-gap: 10px;
          padding: 0 25px;
        }
        `
      }>
        {children}
      </div>
    </div>
  )
}

export default Section
