import React, { useEffect, useState, useRef } from 'react'
import GlobalStyles from "~styles/global"
import Header from '~components/sections/Header'
import Footer from '~components/sections/Footer'
import Transition from "~components/Transition"
import { css, Global } from "@emotion/react"
import { breakpoints } from "~styles/global"
import Menu from '~components/sections/Menu'
import SearchOverlay from '~components/sections/SearchOverlay'
import { useSiteState, useUrlLocation } from '~context/siteContext'
import Modal from '~components/Modal'
import AnnouncementPopup from '~components/blocks/AnnouncementPopup'
import SignUpPopUp from '~components/blocks/SignUpPopUp'
import AnnouncementBar from '~components/sections/AnnouncementBar'
import smoothscroll from 'smoothscroll-polyfill'
import Cookies from 'js-cookie'
import useSiteSettings from '~utils/useSiteSettings'
import resolveLink from '~utils/resolveLink'
import "~styles/static.css"
import { Helmet } from "react-helmet"
import { useLocation } from '@reach/router'

const Layout = ({ children, location, pageContext }) => {
  const { mobile } = breakpoints
  const [siteState, setSiteState] = useSiteState()
  const [loaded, setLoaded] = useState(true)
  const [modalShowing, setModalShowing] = useState(false)
  const [barShowing, setBarShowing] = useState(false)
  const [borderColor, setBorderColor] = useState(`var(--beige)`)
	const [signUpModalShowing, setSignUpModalShowing] = useState(false)

  const topBarRef = useRef()

  const { announcement, topBarMessage, popUpLink, popUpMessage, popUpHeading, signUpPopUp, signUpPopUpTitle, signUpPopUpText, footerContactSuccessMessage } = useSiteSettings()

  useUrlLocation()

  useEffect(() => {
    smoothscroll.polyfill()

    if(!Cookies.get('seenBar') && announcement){
      setBarShowing(true)
      Cookies.set('seenBar', true, { expires: 2 })
    }

		if (!Cookies.get('signUpModalClosed')){
			window.setTimeout(() => {
				setSignUpModalShowing(true)
			}, 4000)
		}

    window.setTimeout(() => {
      setLoaded(true)
    }, 200)

		// if(location.host.includes('netlify')){
		// 	window.location = 'https://misura.com.au/'
		// }
  }, [])

  const handleBarClick = () => {
		setSignUpModalShowing(false)
    setModalShowing(true)
  }

  const handleModalClose = () => {
    setBarShowing(false)
    setModalShowing(false)
  }

	const handlePopUpModalClose = () => {
		setSignUpModalShowing(false)
		Cookies.set('signUpModalClosed', true, { expires: 7 })
	}

  const hideOverlays = () => {
    setSiteState({...siteState, menuOpen: false, searchOpen: false, searchOpen: false})
    setModalShowing(false)
  }

  useEffect(()=> {
    if (siteState.menuOpen || siteState.searchOpen) {
      setBorderColor('var(--black)') 
    } else { 
      setTimeout(()=> {
        setBorderColor('var(--beige)')
      }, 400)
    }
  }, [siteState.menuOpen, siteState.searchOpen])

  if(pageContext.pdf){
    return (
      <div>
        <GlobalStyles />
        {children}
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
      </Helmet>
      <div css={css`
          transition: opacity 0.8s;
          opacity: ${loaded ? 1 : 0};
      `}>
        <GlobalStyles />
        {(siteState.searchOpen || modalShowing) && 
          <Global styles={css`
          body {
            overflow: hidden;
          }
          `}/>
        }
        {announcement &&
        <AnnouncementBar
          ref={topBarRef}
          announcement={topBarMessage} 
          close={() => setBarShowing(false)}
          onClick={() => handleBarClick()}
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            transform: ${barShowing ? 'none' : 'translateY(-100%)'};
            opacity: ${barShowing ? 1 : 0};
            transition: transform 0.3s, opacity 0.3s 0.3s;
          `}
        />
        }
        <Header css={css`
          margin-top: ${topBarRef.current && barShowing ? topBarRef.current.offsetHeight + 'px' : 0};
          transition: margin 0.3s;
        `}/>
        <SearchOverlay css={css`
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          z-index: 80;
          background: var(--beige);
          transition: transform 0.5s, opacity 0.5s;
          transform: ${siteState.searchOpen ? 'translateY(0)' : 'translateY(-101%)'};
          border-bottom: 1px solid;
          border-color: ${borderColor};
          ${mobile}{
            bottom: 0;
          }
        `}/>
        <Menu 
          barShowing={barShowing}
          css={css`
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            z-index: 80;
            background: var(--beige);
            border-bottom: 1px solid;
            border-color: ${borderColor};
            transition: transform 0.5s, opacity 0.5s;
            transform: ${siteState.menuOpen ? 'translateY(0)' : 'translateY(-101%)'};
            padding-top: 146px;
            /* transition-delay: 0s, 0s, 0.3s; */
            padding-bottom: 94px;
            ${mobile}{
              padding-top: 136px;
              border-bottom: none;
              bottom: 0;
            }
        `}/>
        <div 
          role="button"
          onClick={() => hideOverlays()}
          css={css`
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 70;
            pointer-events:${(siteState.menuOpen || siteState.searchOpen) ? 'auto' : 'none'};
        `}/>
        <Transition location={location} onChange={() => hideOverlays()}>
        <div css={css`
          min-height: calc(100vh - 104px);
          display: flex;
          flex-direction: column;
          ${mobile}{
            /* padding-top: 76px;
            min-height: calc(100vh - 76px); */
          }
          `}>
          <main>
            {children}
          </main>
          <Footer css={css`
            padding-top: 236px;
            margin: auto 0 54px;
            ${mobile}{
              padding-top: 160px;
              margin: auto 0 40px;
            }
            `} />
        </div>
        </Transition>
        {(modalShowing && announcement && !process.env.GATSBY_PREVIEW) &&
          <Modal isShowing={true}>
            <AnnouncementPopup 
              close={() => handleModalClose()}
              heading={popUpHeading}
              text={popUpMessage}
              link={resolveLink(popUpLink.to._type, popUpLink.to.slug.current)}
              cta={popUpLink.linkText}
            />
          </Modal>
        }
				{(signUpModalShowing && signUpPopUp && !process.env.GATSBY_PREVIEW) &&
          <Modal isShowing={true}>
            <SignUpPopUp 
              close={() => handlePopUpModalClose()}
              heading={signUpPopUpTitle}
              text={signUpPopUpText}
							successMessage={footerContactSuccessMessage}
            />
          </Modal>
        }
      </div>
    </>
  )
}

export default Layout
