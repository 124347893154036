import { graphql, useStaticQuery } from "gatsby"

const useSiteSettings = () => {

  const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings(_id: {regex: "/(drafts\\\\.)?siteSettings/"}) {
        shippingMessage
        _rawSocialImage(resolveReferences: {maxDepth: 1})
        metaTitle
        metaDescription
        siteTitle
        siteSubtitle
        wholesaleDiscount
        _rawCta(resolveReferences: {maxDepth: 3})
        _rawFooterMenu(resolveReferences: {maxDepth: 10})
        abn
        facebookUrl
        linkedInUrl
        instagramUrl
        pinterestUrl
        journalText
        journalLinkText
        aboutText
        aboutLinkText
        aboutImage {
          asset {
            id
          }
        }
        announcement
        topBarMessage
        _rawPopUpLink(resolveReferences: {maxDepth: 5})
        popUpMessage: _rawPopUpMessage(resolveReferences: {maxDepth: 2})
        popUpHeading
				signUpPopUp
				signUpPopUpTitle
				signUpPopUpText: _rawSignUpPopUpText(resolveReferences: {maxDepth: 2})
        _rawStoreCategories(resolveReferences: {maxDepth: 8})
				storeCategories {
					... on SanityProductCategory {
						id
						title
						image {
							asset {
								url
								id
								title
							}
						}
						text {
							_rawChildren(resolveReferences: {maxDepth: 5})
						}
						slug {
							current
						}
						defaultParentCategory {
							slug {
								current
							}
							title
							_type
						}
						_type
						textSnippet
					}
					... on SanityProductParentCategory {
						id
						title
						text {
							_rawChildren(resolveReferences: {maxDepth: 5})
						}
						image {
							asset {
								url
								id
								title
							}
						}
						categories {
							text {
								_rawChildren
							}
							title
							slug {
								current
							}
							image {
								asset {
									id
									url
								}
							}
							defaultParentCategory {
								slug {
									current
								}
							}
							textSnippet
							_type
							_key
						}
						slug {
							current
						}
						_type
						textSnippet
					}
				}
        featuredArticles {
          datePublished
          _rawContent(resolveReferences: {maxDepth: 5})
          title
          _type
          slug {
            current
          }
          _id
          _key
        }
        accountLoginText {
          _type
          children {
            _key
            _type
            marks
            text
          }
        }
        accountRegisterText {
          _type
          children {
            marks
            text
            _key
            _type
          }
        }
        footerContactSuccessMessage {
          _type
          children {
            marks
            text
            _key
            _type
          }
        }
        productEnquirySuccessMessage {
          _type
          children {
            marks
            text
            _key
            _type
          }
        }
        cartAgreeMessage: _rawCartAgreeMessage(resolveReferences: {maxDepth: 5})
      }
      sanityShowrooms {
        _rawContent
      }
    }
  `)

  const settings = siteSettings.sanitySiteSettings

  return {
    siteTitle: settings.siteTitle,
    siteSubtitle: settings.siteSubtitle,
    siteMetaDescription: settings.metaDescription,
    siteMetaTitle: settings.metaTitle,
    siteSocialImage: settings._rawSocialImage,
    gmapsApiKey: 'AIzaSyAbLQt-t5wr47dC80H_-zicpxjM4xl-kWY',
    cta: settings._rawCta,
    footerLinks: settings._rawFooterMenu,
    abn: settings.abn,
    facebookUrl: settings.facebookUrl,
    linkedInUrl: settings.linkedInUrl,
    instagramUrl: settings.instagramUrl,
    pinterestUrl: settings.pinterestUrl,
    showrooms: siteSettings.sanityShowrooms?._rawContent?.showrooms,
    journalText: settings.journalText,
    journalLinkText: settings.journalLinkText,
    aboutText: settings.aboutText,
    aboutLinkText: settings.aboutLinkText,
    aboutImage: settings.aboutImage,
    storeCategories: settings.storeCategories,
    featuredArticles: settings.featuredArticles,
    shippingMessage: settings.shippingMessage,
    wholesaleDiscount: settings.wholesaleDiscount,
    announcement: settings.announcement,
    topBarMessage: settings.topBarMessage,
    popUpLink: settings._rawPopUpLink,
    popUpMessage: settings.popUpMessage,
    popUpHeading: settings.popUpHeading,
		signUpPopUp: settings.signUpPopUp,
		signUpPopUpTitle: settings.signUpPopUpTitle,
		signUpPopUpText: settings.signUpPopUpText,
    accountLoginText: settings.accountLoginText,
    accountRegisterText: settings.accountRegisterText,
    footerContactSuccessMessage: settings.footerContactSuccessMessage,
    productEnquirySuccessMessage: settings.productEnquirySuccessMessage,
    cartAgreeMessage: settings.cartAgreeMessage
  }
}

export default useSiteSettings
