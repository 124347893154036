import React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from 'gatsby-source-sanity'
import { css } from "@emotion/react"
import usePlaceholder from "~utils/usePlaceholder"

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET
}

class Image extends React.Component {
  // this is temporarily a class component so we can prevent unneccessary re-renders, hoping this bug with Gatsby will be fixed soon
  // https://github.com/gatsbyjs/gatsby/issues/30952

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.className !== nextProps.className || this.props.aspectRatio !== nextProps.aspectRatio || this.props.asset !== nextProps.asset ) {
      return true;
    } else {
      return false;
    }
  }

  render() {

    let assetData
		if (!this.props.placeholder && !this.props.asset) return null

		// console.log(this.props.asset)

		let asset = this.props.asset

		if(asset?.asset){
			asset = asset.asset
		}
		
		//  weird bug where one product had "upload" data
		if(asset?._upload) return null

		// return null

    if (asset) {
      assetData = getGatsbyImageData(asset, { maxWidth: this.props.width || 3000, aspectRatio: this.props.aspectRatio, layout: 'fullWidth' }, sanityConfig)
    } else if (this.props.src) {
      assetData = null
    } else {
      assetData = getGatsbyImageData(this.props.placeholder.asset, { maxWidth: this.props.width || 3000, aspectRatio: this.props.aspectRatio, layout: 'fullWidth' }, sanityConfig)
    }

    return (
      <div className={this.props.className} css={css`
        position: relative;
        > div > div {
          /* this is to allow full resizing of custom set aspectRatios */
          max-width: ${this.props.aspectRatio ? 'none !important' : 'auto'};
        }
        `} >
        {assetData ? (
          <GatsbyImage image={assetData} alt={this.props.alt ?? ''} style={{display: 'block', pointerEvents: 'none'}}/>
        ) : (
          <div css={css`
            height: ${this.props.aspectRatio ? '0' : 'auto'};
            padding-bottom: ${this.props.aspectRatio ? (this.props.aspectRatio * 100) + '%' : '0'};
            width: 100%;
            `}>
            <img src={this.props.src} alt={this.props.alt ?? ''} css={css`
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              object-fit: cover;
              `}/>
          </div>
        )}
      </div>
    )
  }
}

const ImageWithData = props => {
  const placeholder = usePlaceholder()
  return <Image {...props} placeholder={placeholder} />
}

export default ImageWithData
